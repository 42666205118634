import './App.css';
import Header from "./components/Header";
import Contact from "./components/Contact";
import Services from "./components/Services";
import About from "./components/About";
// import Footer from './components/Footer';

export default function App() {
  return (
    <main className="bg-white">
      <Header />
      <About />
      <Services />
      <Contact />
    </main>
  );
}


