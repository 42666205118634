import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./carouselstyles.css";

const chimneyImages = [
    'ch1.jpg',
    'ch2.jpg',
    'ch3.jpg',
    'ch4.jpg',
    'ch5.jpg',
    'ch6.jpg',
    'ch7.jpg',
    'ch8.jpg',
    'ch9.jpg',
    'ch10.jpg',
    'ch11.jpg',
    'ch12.jpg',
    'ch13.jpg',
    'ch14.jpg',
    'ch15.jpg',
    'ch16.jpg',
    'ch17.jpg',
    'ch18.jpg',
    'ch19.jpg',
    'ch20.jpg',
    'ch21.jpg',
];

export default function Services() {
    return (
        <section id="services" className="text-black text-center scroll-mt-20 flex flex-col md:flex-row">
            <div className=" bg-white rounded-lg container mx-auto px-10 py-10 flex-grow md:w-3/4">
                <div className="flex flex-col w-full">
                    <h1 className="sm:text-4xl text-3xl font-serif font-medium text-black">
                        Services
                    </h1>
                    <div className="text-left">
                        <details className="my-4">
                            <summary className="text-2xl font-serif font-medium"> Consultations</summary>
                            For many new home owners as well as established residents, a big question is what should I do with my chimney? Is it presently safe to use? Is it structurally sound? Is it a seismic hazard? Should I convert to gas or is an electric fireplace the way forward? Should I remove some or all of the chimney structure? What are my options and how much do they cost? With over 30 years of experience, we can answer these questions.  The cost of a consultation is $200, which is credited towards any work that is subsequently performed.
                        </details>
                        <details className="my-4">
                            <summary className="text-2xl font-serif font-medium"> Repairs</summary>
                            Whether the chimney is used or not, it may well need masonry repairs, waterproofing, cap and damper replacements. We can evaluate what is needed and take care of the problems.
                        </details>
                        <details className="my-4">
                            <summary className="text-2xl font-serif font-medium"> Chimney Removal</summary>
                            It is an unfortunate fact that at some point most Bay Area chimneys are coming down one way or another. As we transition away from wood burning, many people are opting to pre-emptively remove this obvious seismic hazard. Almost no chimneys built before 1940 have any steel reinforcement and masonry strength and integrity diminishes with age. For many people, having a one ton brick tower perched over their house is a seismic risk they can do without. Every chimney is different just as every client is unique. What is appropriate in one situation may not be in another. We all have different priorities and different budgets. We offer affordable chimney removal, full or partial and estimates for this service are generally free of charge.
                        </details>
                    </div>
                </div>
            </div>
            <div className="align-middle justify-center ml-7 md:m-5 w-5/6 md:w-full h-3/4 bg-white">
                <Carousel showArrows={true} showThumbs={false} showStatus={true} infiniteLoop={true}>
                    {chimneyImages.map((image, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '675px' }}>
                            <img
                                src={process.env.PUBLIC_URL + '/chimneys/' + image}
                                alt={`Chimney ${index + 1}`}
                                style={{ maxHeight: '675px', width: 'auto', height: 'auto' }}
                            />
                        </div>
                    ))}
                </Carousel>
            </div>

        </section >
    );
}
